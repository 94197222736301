import metafieldsItems from '../fragments/metafields'

const getArticleQuery = /* GraphQL */ `
  query getArticleByHandle($blog: String!,$slug: String!) {
    blog:blogByHandle(handle: $blog) {
      handle
      title
      article:articleByHandle(handle: $slug) {
        handle
        id
        title
        handle
        seo {
          title
          description
        }
        image {
          url
          altText
        }
        tags
        title
        publishedAt
        content
        contentHtml
        excerpt
        excerptHtml
        authorV2 {
          bio
          email
          firstName
          lastName
          name
        }
        MF_author: metafield(namespace: "info", key: "author") {
                ${metafieldsItems}
        }
        MF_pageType: metafield(namespace: "pageType", key: "category") {
          ${metafieldsItems}
        }
      }
      articles(first: 250) {
        edges {
          node {
            handle
            title
            publishedAt
          }
        }
      }
      MF_faq: metafield(
        namespace: "content"
        key: "faq"
      ) {
          ${metafieldsItems}
      }
      MF_postsRecommend: metafield(namespace: "posts", key: "recommend") {
        ${metafieldsItems}
      }
      MF_pageType: metafield(namespace: "pageType", key: "category") {
        ${metafieldsItems}
      }
    }
  }
`

export { getArticleQuery }
