import { format, parse } from 'date-fns'
import { useState, useEffect } from 'react'
import { Picture, Text } from '@components/ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import { cn, rmEmptyKeys } from '@components/helper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import s from './index.module.css'
import BannerLink from '@components/common/BannerLink'
import ProgressBar from '../../../templates/Pages/HomePps/component/ProgressBar'

import { useRouter } from 'next/router'
import { handleHref } from 'templates/Pages/Nano/utilities'
import { useArticle } from 'lib/shopify/api/blog/use-article'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const Index = ({ id, data, pageHandle, abtest }) => {
  const { setRelativeLink } = useRelativeLink()

  const [swiperRef, setSwiperRef] = useState(null)

  const settings = {
    speed: 400,
    navigation: true,
    slidesPerView: 1,
    spaceBetween: 16,
    autoplay: data?.autoplay || false,
    modules: [Navigation, Autoplay, Pagination],
    pagination:
      data?.list?.length > 2
        ? {
            clickable: true,
            bulletActiveClass: s.activeBullet,
            bulletClass: s.bullet,
          }
        : false,
    breakpoints: {
      0: {
        spaceBetween: 12,
        slidesPerView: 1.1,
      },
      768: {
        spaceBetween: 12,
        slidesPerView: 1.1,
      },
      1023: {
        spaceBetween: 12,
        slidesPerView: 2,
      },
    },
    slideToClickedSlide: true,
  }

  useEffect(() => {
    document
      ?.querySelector(`#${id} .swiper-button-next`)
      ?.addEventListener('click', function () {
        window.dataLayer.push({ event_parameters: null })
        window.dataLayer.push({
          event: 'ga4Event',
          event_name: 'lp_button',
          event_parameters: {
            page_group: 'Activity Page_' + pageHandle,
            position: 'NewBlogs',
            button_name: 'next',
          },
        })
      })
    document
      ?.querySelector(`#${id} .swiper-button-prev`)
      ?.addEventListener('click', function () {
        window.dataLayer.push({ event_parameters: null })
        window.dataLayer.push({
          event: 'ga4Event',
          event_name: 'lp_button',
          event_parameters: {
            page_group: 'Activity Page_' + pageHandle,
            position: 'NewBlogs',
            button_name: 'prev',
          },
        })
      })

    document?.querySelectorAll(`#${id} .${s.bullet}`)?.forEach((e) => {
      e?.addEventListener('click', function () {
        window.dataLayer.push({ event_parameters: null })
        window.dataLayer.push({
          event: 'ga4Event',
          event_name: 'lp_button',
          event_parameters: {
            page_group: 'Activity Page_' + pageHandle,
            position: 'NewBlogs',
            button_name: 'tab',
          },
        })
      })
    })
  }, [])

  return (
    <section id={id} className="relative leading-[1.2]">
      <div
        className={cn('absolute left-0 top-0 h-full w-full overflow-hidden')}
      >
        <Picture
          source={`${data?.bgImg}, ${data?.mobBgImg || data?.bgImg} 768`}
          className={cn('h-full w-full  md:h-auto [&_img]:h-full')}
        />
      </div>
      <div
        className={cn(
          'layer relative overflow-hidden py-[140px] md:py-[80px]',
          {
            ['pt-0 md:pt-0']: data?.delPaddingTop,
            ['pb-0 md:pb-0']: data?.delPaddingBottom,
          }
        )}
      >
        <div className="content !max-w-[1410px]">
          <div className="flex h-full w-full flex-col justify-center">
            <div className="mb-[50px] flex flex-col justify-between md:mb-[28px]">
              {data?.title && (
                <Text
                  html={data.title}
                  className={cn(
                    ' text-[48px] font-[700] uppercase text-[#fff]  md:text-[32px]',
                    {
                      '!text-center': abtest,
                    }
                  )}
                />
              )}

              {data?.learnMore && (
                <a
                  className={cn(
                    'mt-[5px] flex text-[18px] font-semibold text-[#fff] transition duration-150  ease-in-out hover:text-[#00A9E0] hover:underline md:hidden',
                    {
                      '!text-center block': abtest,
                    }
                  )}
                  href={`${setRelativeLink({ link: data?.link })}?ref=${
                    data?.ref || pageHandle
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: data?.learnMore,
                  }}
                />
              )}
            </div>

            <div>
              <Swiper
                {...settings}
                className={s.swiperBox}
                onSwiper={setSwiperRef}
              >
                {data?.list?.map((item, index) => (
                  <SwiperSlide key={index} className={s.swiperSlide}>
                    <Row
                      item={item}
                      data={data}
                      key={index}
                      index={index}
                      pageHandle={pageHandle}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <style jsx global>{`
        #${id} .swiper-button-prev,
        #${id} .swiper-button-next {
          width: 18px;
          opacity: 0;
          height: 33px;
          color: #fff;
          transition: all 200ms ease-out;
        }

        #${id} .swiper-button-prev {
          left: 40px;
        }

        #${id} .swiper-button-next {
          right: 40px;
        }

        #${id} .${s.swiperBox}:hover .swiper-button-prev,
        #${id} .${s.swiperBox}:hover .swiper-button-next {
          opacity: 1;
        }

        #${id} .${s.swiperBox}:hover .swiper-button-disabled {
          opacity: 0.35;
        }

        #${id} .swiper-button-next:after,
        #${id} .swiper-rtl .swiper-button-prev:after,
        #${id} .swiper-button-prev:after,
        #${id} .swiper-rtl .swiper-button-next:after {
          font-size: 33px;
        }

        #${id} .${s.swiperBox} .swiper-pagination {
          bottom: -40px;
          display: flex;
          gap: 5px;
          justify-content: center;
        }

        @media (max-width: 769px) {
          #${id} .swiper-button-next,
          #${id} .swiper-button-prev {
            display: none;
          }
          #${id} .${s.swiperBox} .swiper-pagination {
            bottom: -30px;
          }
        }
      `}</style>
    </section>
  )
}

export default Index

const Row = ({ item, data, pageHandle, index }) => {
  const { locale } = useRouter()
  const {
    isLoading,
    data: article,
    isEmpty,
  } = useArticle(item?.blog, item?.slug)

  const { setRelativeLink } = useRelativeLink()

  const link =
    item?.blog && item?.slug
      ? handleHref(
          locale,
          `blogs/${item?.blog}/${item?.slug}?ref=${
            data?.ref ? data?.ref + (index + 1) : pageHandle
          }`
        )
      : `${setRelativeLink({ link: item?.link })}?ref=${
          data?.ref ? data?.ref + (index + 1) : pageHandle
        }`
  return (
    <div className={cn(s.hoverBox, 'hoverBox')}>
      <Picture
        source={`${item?.img || article?.image?.url}, ${
          item?.mobImg || item?.img || article?.image?.url
        } 768`}
        className={cn(
          'aspect-h-[480] aspect-w-[696] w-full md:aspect-h-[400] md:aspect-w-[335] md:h-auto [&_img]:object-cover'
        )}
      />

      <div className={s.absoluteBox}>
        <div className={cn(s.textWrap, 'textWrap')}>
          {link && <BannerLink to={link} />}
          <Text
            html={article?.seo?.title || article?.title || item.title}
            className={cn(s.itemTitle, s.description, 'itemTitle')}
          />

          {(article?.publishedAt || item.subtitle) && (
            <Text
              html={
                article?.publishedAt
                  ? format(
                      parse(article?.publishedAt, 'dd/MM/yyyy', new Date()),
                      'MMM dd, yyyy'
                    )
                  : item.subtitle
              }
              className={cn(s.colDesc, 'colDesc')}
              style={rmEmptyKeys({ color: data.descColor })}
            />
          )}
          <div className={cn(s.arrowWrap, 'arrowWrap')}>
            <div className={cn(s.learnMore, 'learnMore')}>
              <Text
                html={
                  article?.seo?.description || article?.content || item?.desc
                }
                className={s.description}
              />
              <Picture
                source={`${data?.arrow}`}
                className={cn(s.arrow, 'arrow')}
              />
            </div>
          </div>
        </div>
      </div>

      <ProgressBar
        s={{ progressBarBox: `${s.progressBarBox} progressBarBox` }}
      />
    </div>
  )
}
