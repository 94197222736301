import Cookies from 'js-cookie'
import useSWR, { preload } from 'swr'
import { useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import { Article as ArticleType } from 'lib/shopify/types'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import { getArticleQuery } from 'lib/shopify/queries/articleByHandle'
import { Article } from 'lib/shopify/gql'
import { format, parseISO } from 'date-fns'

export const validSortKey = ['PRICE', 'RELEVANCE']

preload('/api/user', getArticle)

export const normalizeArticle = (
  { publishedAt, excerpt, seo, ...article }: Article,
  previewData?: any,
  resource_type?: any
) => {
  return {
    ...article,
    ...(publishedAt && {
      publishedAt: format(new Date(publishedAt), 'dd/MM/yyyy'),
    }),
    seo: seo || {},
    excerpt: excerpt || seo?.description || '',
  }
}

export async function getArticle(input: ArticleType.ArticleQuery) {
  const { blog, slug, locale } = input
  const { res } = await fetchGraphqlApi<any>({
    query: getArticleQuery,
    locale,
    variables: {
      blog,
      slug,
    },
    cache: 'no-store',
  })

  const { article } = res && res.blog ? res.blog : { article: null }

  return article ? normalizeArticle(article) : null
}

export function useArticle(blog: string, slug: string) {
  const { locale = 'us' } = useRouter()

  const response = useSWR({ locale, blog, slug }, getArticle, {
    keepPreviousData: true,
  })

  return useMemo(
    () =>
      Object.defineProperties(response, {
        isEmpty: {
          value: !response.data,
          enumerable: true,
        },
        isLoading: {
          value: response.isLoading,
          enumerable: true,
        },
      }),
    [response]
  )
}
